import React from 'react';

import Navbar from '../components/Navbar';
import Card from '../components/Card';

function HomePage() {
  return (
    <div>
      <Navbar />
      <Card />
    </div>
  );
}

export default HomePage;